<template>
  <div id="form-crud-shop-event">
    <form @submit.prevent="initSubmit">
      <div class="form-group">
        <label for="select-event">Type d'événement</label>
        <select id="select-event" class="form-control" v-model="selectTypeEvent">
          <option v-for="(event, index) in events" :key="index" :value="event.id">
            {{ event.name }}
          </option>
        </select>
        <div v-if="errorsForm.parent">
          <p class="form-error text-danger" v-for="(error, index) in errorsForm.parent.errors" :key="index">{{ error }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <label for="date-start">Date de début</label>
          <Datepicker
              id="date-start"
              v-model="shopEvent.startDate"
              :format="formatDate"
              :minimumView="'day'"
              :maximumView="'day'"
              :disabledDates="disabledDatesForCurrentYear"
              @selected="clickDatePickerStartDate"
          ></Datepicker>
          <div v-if="errorsForm.startDate">
            <p class="form-error text-danger" v-for="(error, index) in errorsForm.startDate.errors" :key="index">{{ error }}</p>
          </div>
        </div>
        <div class="col">
          <label for="date-end">Date de fin</label>
          <Datepicker
              id="date-end"
              v-model="shopEvent.endDate"
              :format="formatDate"
              :minimumView="'day'"
              :maximumView="'day'"
              :disabledDates="disabledDatesForCurrentYear"
          ></Datepicker>
          <div v-if="errorsForm.endDate">
            <p class="form-error text-danger" v-for="(error, index) in errorsForm.endDate.errors" :key="index">{{ error }}</p>
          </div>
        </div>
      </div>
      <div v-if="(selectTypeEvent === 1 || selectTypeEvent === 3) && (shopEvent.startDate != null && shopEvent.endDate != null)" class="mt-4 mb-4 border p-2">
        <div class="alert alert-info" role="alert">
          <b>ATTENTION</b> : les dates suivantes, seront retirées des comparaisons sur le chiffre d'affaires !
        </div>
        <div class="form-row">
          <div class="col">
            <label>Date d'équilibrage</label>

            <div v-for="(datepicker, index) in listDatePicker" :key="index">
              <div class="d-flex justify-content-between mt-3">
                <Datepicker
                    :id="'datepicker-' + datepicker"
                    input-class="w-100"
                    class="w-75"
                    :format="formatDate"
                    :minimumView="'day'"
                    :maximumView="'day'"
                    :disabledDates="disabledDatesForYearLessOne"
                    :key="index"
                    :open-date="(actionForm === 'create') ? tempDatepickerValue : new Date()"
                    @selected="clickDatePickerListDateRemoveSale($event, index)"
                    :value="(actionForm === 'edit') ? (shopEvent.shopsEventDate[index]) ? shopEvent.shopsEventDate[index].date : '' : ''"
                ></Datepicker>
                <div class="d-flex">
                  <span v-if="(actionForm === 'create') ? listDatePicker > 1 && index > 0 : index > 0" class="btn btn-danger btn-sm mr-2">
                    <b-icon class="" icon="dash" font-scale="1" variant="white" v-on:click.prevent="removeDatepicker(index)"></b-icon>
                  </span>
                  <span class="btn btn-primary btn-sm">
                    <b-icon class="" icon="plus" font-scale="1" variant="white" v-on:click.prevent="addDatepicker"></b-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-2">
        <label for="reason-textarea">Raison</label>
        <textarea class="form-control" id="reason-textarea" rows="3" v-model="shopEvent.comment"></textarea>
        <div v-if="errorsForm.comment">
          <p class="form-error text-danger" v-for="(error, index) in errorsForm.comment.errors" :key="index">{{ error }}</p>
        </div>
      </div>

      <div class="d-flex justify-content-between mt-4">
        <p
            v-if="actionForm === 'edit'"
            class="btn btn-outline-warning mb-4"
            v-on:click="cancelEditMode">
          Annuler
        </p>
        <div>
          <input type="submit" class="btn btn-success mb-4" :value="valueButtonSubmit" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Moment from 'moment'
import {mapState} from "vuex";

export default {
  props: {
    shop: Object,
    events: Array,
    shopEvent: Object,
    selectedTypeEvent: Number,
    actionForm: String,
    countDatepicker: Number,
    valueButtonSubmit: String,
    shopEventId: Number
  },
  data() {
    return {
      errorsForm: [],
      formatDate: 'dd/MM/yyyy',
      listDatePicker: 1,
      tempDatepickerValue: '',
      selectTypeEvent: this.selectedTypeEvent,
      disabledDatesForCurrentYear: {
        to: new Date(new Date().getFullYear(), 0, 1), // Disable all dates up to specific date
        from: new Date(new Date().getFullYear() + 1, 0, 1), // Disable all dates after specific date
      },
      disabledDatesForYearLessOne: {
        to: new Date(new Date().getFullYear() - 1, 0, 1), // Disable all dates up to specific date
        from: new Date(new Date().getFullYear(), 0, 1), // Disable all dates after specific date
      }
    }
  },
  watch: {
    countDatepicker: function() {
      this.listDatePicker = this.countDatepicker
    },
    shopEvent: function() {
      this.shopEvent
    },
    selectedTypeEvent : function() {
      this.selectTypeEvent = this.selectedTypeEvent
    }
  },
  methods: {
    changeEvent: function() {
      this.$emit('input',this.selectTypeEvent)
    },
    initSubmit() {
      if(this.shopEvent.comment === null) {
        this.shopEvent.comment = ''
      }

      // create or update
      if(this.actionForm === 'edit') {
        this.editShopEvent()
      } else {
        this.createShopEvent()
      }
    },
    /**
     * Create a new shop event
     */
    createShopEvent() {
      // Handle Date
      this.handleDate()

      // handle select
      this.shopEvent.event = this.selectTypeEvent
      this.shopEvent.shopsEventDate = this.shopEvent.listDateRemoveSales

      this.$store.dispatch('createShopEvent', {
        centerId: this.$store.getters.getChannel.center.id,
        shopEvent: this.shopEvent
      })
        .then(() => {
          // Reset errorsForm
          this.errorsForm = []

          // Emit new shop event
          this.$emit('created')

          // Reset
          this.shopEvent.listDateRemoveSales = []
          this.tempDatepickerValue = ''

          this.$bvToast.toast('La création de l\'événement à été effectué avec succès', {
            title: 'Création d\'Evénement',
            variant: 'success',
            solid: true
          })
        })
        .catch((errors) => {
          this.errorsForm = errors
        })
    },
    /**
     * Update shop event
     */
    editShopEvent() {
      // Handle Date
      this.handleDate()

      // Set event and shop
      this.shopEvent.event = this.selectTypeEvent
      this.shopEvent.shop = this.shopEvent.shop.id

      if(this.shopEventId !== null)
      {
        this.$store.dispatch('updateShopEvent', {
          centerId: this.$store.getters.getChannel.center.id,
          id: this.shopEventId,
          shopEvent: this.shopEvent
        })
            .then(() => {
              // Emit edit shop event
              this.$emit('edited')

              this.$bvToast.toast('L\'édition de l\'événement à été effectué avec succès', {
                title: 'Edition d\'Evénement',
                variant: 'success',
                solid: true
              })
            })
            .catch((errors) => {
              this.errorsForm = errors
            })
      }
    },
    /**
     * Autocomplete date remove with classic date
     */
    handleDate()
    {
      if(this.shopEvent.startDate != null)
        this.shopEvent.startDate = Moment(this.shopEvent.startDate).format('yyyy-MM-DD')
      if(this.shopEvent.endDate != null)
        this.shopEvent.endDate = Moment(this.shopEvent.endDate).format('yyyy-MM-DD')
    },
    /**
     * Event on click after choose date on date start datepicker
     * @param date
     */
    clickDatePickerStartDate(date)
    {
      if(this.selectTypeEvent === 1 || this.selectTypeEvent === 3) {
        this.tempDatepickerValue = new Date(Moment(date).subtract(1, 'years').format('yyyy-MM-DD'))
      } else {
        this.tempDatepickerValue = ''
      }
    },
    addDatepicker() {
      this.listDatePicker = this.listDatePicker + 1
    },
    removeDatepicker(index) {

      // Remove form list
      let indexDate = this.shopEvent.listDateRemoveSales.findIndex(obj => obj.index === index)
      if (indexDate > -1) {
        this.shopEvent.listDateRemoveSales.splice(indexDate, 1)
      }

      this.listDatePicker = this.listDatePicker - 1
    },
    clickDatePickerListDateRemoveSale($event, index) {
      let model = {
        index: index,
        date: Moment($event).format('yyyy-MM-DD')
      }

      let indexDate = this.shopEvent.listDateRemoveSales.findIndex(obj => obj.index === index)
      if (indexDate > -1) {
        this.shopEvent.listDateRemoveSales.splice(indexDate, 1)
      }

      this.shopEvent.listDateRemoveSales.push(model)
    },
    cancelEditMode() {
      this.$emit('cancel')
    },
  },
  computed: {
    ...mapState(['ChannelModule']),
  },
  components: {
    Datepicker: () => import('vuejs-datepicker')
  }
}
</script>
